.main {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3fr 0fr;
  grid-gap: 0px;
}

.video-area {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 0;
  background-color: #263346;
  border-radius: 10px;
}

.bottom-area {
  display: none;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 0;
}

#counter {
  position: absolute;
  z-index: 100;
  top: 100%;
  width: 200%;
  display: table;
  text-align: center;
  padding: 10px 15px;
  font-size: 20px;
  -webkit-transition: font-size 1s linear;
  transition: font-size 1s linear;
  height: 18%;
}

#counter_txt {
    display: table-cell;
    vertical-align: middle;
}

.alerte {
  animation: blinker 0.3s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.video video {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video.remote video {
  border-radius: 10px 0 0 10px;
}

.video.local video {
  border-radius: 0 10px 10px 0;
}

.video {
  position: relative;
  height: 100%;
  width: 100%;
}



@-webkit-keyframes rotating
/* Safari and Chrome */

  {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
  width: 200%;
}
