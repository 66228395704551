.left {
  background-color: #263346;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.buttons > button {
  margin: 0 10px;
  padding: 15px;
  outline: none;
  border: none;
  border-radius: 10px;
  transition: 0.2s;
  cursor: pointer;
  height: 70px;
  width: 100px;
  font-size: 100%;
}

.buttons .next {
  background-color: #51fd6d;
}
.buttons .next svg {
  height: 90%;
  width: 90%;
}

.buttons > button:hover {
  transform: scale(1.03);
  box-shadow: 3px 3px #1a1f25;
}

.buttons > button:active {
  transform: scale(1.03);
  box-shadow: 3px 3px #1a1f25;
}

.circle {
  margin-left: 5px;
  margin-top: 4px;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.left span {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fafafa;
}
